<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">You can check about future product plans. </strong>
    </div>

    <div class="container mt-5 mb-5">
      <div class="calendar p-3">
        <div class="header">
          <button @click="prevMonth">&lt;</button>
          <span>{{ monthNames[currentMonth] }} / {{ currentYear }}</span>
          <button @click="nextMonth">&gt;</button>
        </div>
        <div class="weekdays bg2">
          <div v-for="day in weekdays" :key="day" class="weekday">
            {{ day }}
          </div>
        </div>
        <div class="days bg1">
          <div
            v-for="(day, index) in days"
            :key="index"
            @click="openPopup(day)"
            :class="{
              day: true,
              'has-border-left': index % 7 === 0,
              'has-border-top': index < 7,
              'has-event': hasEvent(day),
            }"
          >
            <span>{{ day }}</span>
            <span v-if="hasEvent(day)" class="dot"></span>
          </div>
        </div>

        <el-dialog
          title="Future Planning"
          :visible.sync="dialogVisible"
          align-center
        >
          <el-table :data="popupData">
            <el-table-column
              min-width="15%"
              prop="code"
              label="Code"
              sortable
            ></el-table-column>
            <el-table-column
              min-width="8%"
              prop="dash"
              label="Dash"
            ></el-table-column>
            <el-table-column
              min-width="50%"
              prop="description"
              label="Description"
              sortable
            ></el-table-column>
            <el-table-column
              min-width="7%"
              prop="quantity"
              label="QTY"
            ></el-table-column>
            <el-table-column
              min-width="12%"
              prop="firstDate"
              label="ETA"
            ></el-table-column>
            <<el-table-column
              min-width="8%"
              label="GET"
              class="text-end"
              v-if="token"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="dialogVisible = false"
                  ><i class="el-icon-view"></i
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";

import { futurelist } from "@/api/index";

export default {
  name: "PcFuterIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      dialogVisible: false,
      popupData: [],
      selectedDay: null,
      events: [], // 存储接口返回的数据
    };
  },
  computed: {
    days() {
      const firstDayOfMonth = new Date(
        this.currentYear,
        this.currentMonth,
        1
      ).getDay();
      const totalDays = new Date(
        this.currentYear,
        this.currentMonth + 1,
        0
      ).getDate();
      const daysArray = [];
      for (let i = 1 - firstDayOfMonth; i <= totalDays; i++) {
        daysArray.push(i > 0 ? i : "");
      }
      return daysArray;
    },
  },
  watch: {},
  mounted() {
    this.flist();
    //console.log(this.events);
  },

  methods: {
    flist() {
      this.isLoading = true;
      const self = this; // 将 this 赋值给一个变量
      const params = {
        webType: "2",
        pid: "0",
        page: this.currentPage,
        size: this.perPage,
        year: this.currentYear,
        month: this.currentMonth + 1,
      };
      futurelist(params)
        .then(function (res) {
          //console.log("Response data:", res.data); // 调试语句
          self.events = res.data; // 使用变量 self 替代 this
          //console.log("this.events:", self.events); // 调试语句
          self.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          self.isLoading = false;
        });
    },

    prevMonth() {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
      this.flist();
    },
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
      this.flist();
    },

    openPopup(day) {
      if (this.hasEvent(day)) {
        const eventsOfDay = this.events.filter((event) => {
          const eventDay = new Date(event.firstDate).getDate();
          return eventDay === day;
        });
        this.popupData = eventsOfDay;
        this.dialogVisible = true;
      }
    },
    hasEvent(day) {
      // 确保 this.events 存在且是一个数组
      if (this.events && Array.isArray(this.events)) {
        const filteredEvents = this.events.filter((event) => {
          const eventDay = new Date(event.firstDate).getDate();
          return eventDay === day;
        });
        return filteredEvents.length > 0; // 检查过滤后的数组是否为空
      }
      return false; // 如果 this.events 不存在或不是数组，则返回 false
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

/* 样式可以根据需要进行自定义 */
.calendar {
  border: 1px solid var(--el-border-color);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.2rem;
    button {
      background-color: transparent;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: var(--bs-primary);
      }
    }
  }

  .weekdays {
    display: flex;
    border: 1px solid var(--el-border-color);
    border-bottom: none;
    .weekday {
      flex: 1;
      text-align: center;
      font-size: 1.05rem;
      padding: 0.8rem;
    }
  }

  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    .has-event {
      position: relative;
    }
  }

  .days .day {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 1rem;
    height: 10rem;
    cursor: pointer;
    border: 1px solid var(--el-border-color);
    border-left: none;
    border-top: none;
    font-size: 1.2rem;
    font-weight: 500;
    transition: all 0.3s ease;
    &:hover {
      background-color: var(--bs-light);
      color: var(--bs-black);
    }

    .dot {
      position: absolute;
      bottom: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      background-color: var(--bs-primary);
      border-radius: 50%;
    }
  }

  .days .day.has-border-left {
    border-left: 1px solid var(--el-border-color);
  }
  .days .day.has-border-top {
    border-top: 1px solid var(--el-border-color);
  }
}
</style>
